// Filename: frontend/src/pages/UserCheckIn.js

import React, { useState, useEffect } from "react";
import config from "../config";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const UserCheckIn = () => {
  const { t } = useTranslation();
  const [roundedTime, setRoundedTime] = useState(new Date());
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [checkInMessage, setCheckInMessage] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    const fetchScheduledTime = async () => {
      try {
        const response = await fetch(
          `${config.API_URL}/user/checkin/get-start-time/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const [hours, minutes] = data.scheduleTime.split(":").map(Number);
          setHour(hours);
          setMinute(minutes);

          // Set roundedTime based on fetched schedule
          const currentDate = new Date();
          currentDate.setHours(hours, minutes, 0, 0);
          setRoundedTime(currentDate);
        } else {
          initializeTime(); // Fallback to nearest 15-minute interval if no schedule is found
        }
      } catch (error) {
        console.error("Error fetching scheduled time:", error);
        initializeTime(); // Fallback on error
      }
    };

    const initializeTime = () => {
      const currentDate = new Date();
      const nearestIntervalMinutes =
        Math.floor(currentDate.getMinutes() / 15) * 15;
      currentDate.setMinutes(nearestIntervalMinutes, 0, 0);
      setRoundedTime(currentDate);
      setHour(currentDate.getHours());
      setMinute(nearestIntervalMinutes);
    };

    fetchScheduledTime();
  }, []);

  const handleCheckIn = async () => {
    setIsCheckingIn(true);
    const userId = localStorage.getItem("userId");
    const checkinDate = new Date(roundedTime);
    checkinDate.setHours(hour);
    checkinDate.setMinutes(minute);

    const formattedDate = checkinDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    try {
      const response = await fetch(`${config.API_URL}/user/checkin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          checkinTime: formattedDate,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setCheckInMessage(data.message || t("checkinSuccess"));
    } catch (error) {
      setCheckInMessage(t("checkinError"));
      console.error("Check-in error:", error.message);
    } finally {
      setIsCheckingIn(false);
    }
  };

  const adjustTime = (adjustment) => {
    let newMinutes = minute + adjustment;
    let newHour = hour;

    if (newMinutes >= 60) {
      newMinutes = 0;
      newHour = (hour + 1) % 24;
    } else if (newMinutes < 0) {
      newMinutes = 45;
      newHour = (hour - 1 + 24) % 24;
    }

    setHour(newHour);
    setMinute(newMinutes);
    const adjustedDate = new Date(roundedTime);
    adjustedDate.setHours(newHour, newMinutes, 0, 0);
    setRoundedTime(adjustedDate);
  };

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = [0, 15, 30, 45];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          {t("check_in_title")}
        </h2>

        {checkInMessage && (
          <p
            className={`text-center mb-4 ${
              isCheckingIn ? "text-yellow-500" : "text-green-500"
            }`}
          >
            {checkInMessage}
          </p>
        )}

        <div className="flex justify-center items-center mb-4">
          <button
            onClick={() => adjustTime(-15)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-3 rounded"
          >
            <FaArrowLeft />
          </button>
          <select
            value={hour}
            onChange={(e) => setHour(Number(e.target.value))}
            className="p-2 border rounded w-20 mr-2"
          >
            {hours.map((h) => (
              <option key={h} value={h}>
                {h.toString().padStart(2, "0")}
              </option>
            ))}
          </select>

          <select
            value={minute}
            onChange={(e) => setMinute(Number(e.target.value))}
            className="p-2 border rounded w-20"
          >
            {minutes.map((m) => (
              <option key={m} value={m}>
                {m.toString().padStart(2, "0")}
              </option>
            ))}
          </select>

          <button
            onClick={() => adjustTime(15)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-3 rounded ml-4"
          >
            <FaArrowRight />
          </button>
        </div>

        <button
          onClick={handleCheckIn}
          disabled={isCheckingIn}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          {isCheckingIn ? t("checkingIn") : t("confirm")}
        </button>
      </div>
    </div>
  );
};

export default UserCheckIn;
