import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config"; // Adjust path as needed
import { useTranslation } from "react-i18next";
import TaskSelection from "../components/TaskSelection"; // Import the TaskSelection component
import { TenantContext } from "../contexts/TenantProvider";

const UserEditProfile = () => {
  const tenant = useContext(TenantContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    employeeNr: "",
  });
  const [selectedTasks, setSelectedTasks] = useState([]); // Track selected tasks
  const [errorMessage, setErrorMessage] = useState("");
  //const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data on component mount
    const userId = localStorage.getItem("userId");
    fetch(`${config.API_URL}/user/get-user-data`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId }), // Pass userId in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load user data");
        }
        return response.json();
      })
      .then((data) => {
        // Populate form fields with fetched data
        setFormData({
          name: data.username,
          email: data.email || "", // Ensure email is set to empty string if null
          employeeNr: data.employee_nr,
        });
        console.log(data);
        // Remove duplicates and set the tasks
        const uniqueTasks = [...new Set(data.task ? data.task.split(",") : [])]; // Remove duplicates
        setSelectedTasks(uniqueTasks); // Populate selected tasks
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");

    // Use useContext to access tenant data
    const tenantId = tenant.tenantId;

    // Add the default role if it's missing
    const role = "employee"; // Assuming the role should be set to 'employee'

    fetch(`${config.API_URL}/user/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: formData.name, // username (name in frontend form)
        email: formData.email,
        employee_nr: formData.employeeNr, // employee number
        role: role, // Set the default role
        tasks: selectedTasks, // tasks array
        userId, // User ID from localStorage
        tenantId, // Tenant ID from context
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update profile");
        }
        return response.json();
      })
      .then(() => {
        // navigate("/user/profile"); // Redirect to profile page or another appropriate page
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl mb-4">{t("edit_profile")}</h2>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1" htmlFor="name">
            {t("name")}
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border rounded w-full p-2"
            required
          />
        </div>
        <div>
          <label className="block mb-1" htmlFor="email">
            {t("email")}
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border rounded w-full p-2"
            required
          />
        </div>
        <div>
          <label className="block mb-1" htmlFor="employeeNr">
            {t("employee_number")}
          </label>
          <input
            type="text"
            name="employeeNr"
            value={formData.employeeNr}
            onChange={handleChange}
            className="border rounded w-full p-2"
            required
          />
        </div>

        {/* Include TaskSelection component */}
        <TaskSelection
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
        />

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          {t("save_changes")}
        </button>
      </form>
    </div>
  );
};

export default UserEditProfile;
