// src/pages/UserRegister.js

// Filename: src/pages/UserRegister.js

import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TaskSelection from "../components/TaskSelection";
import config from "../config";
import { TenantContext } from "../contexts/TenantProvider";

const UserRegister = ({ onLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tenantName, setTenantName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [isIdValid, setIsIdValid] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    employee_nr: "",
    password: "",
    confirmPassword: "",
    role: "employee",
  });
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    username: "",
    password: "",
    employee_nr: "",
    confirmPassword: "",
    tasks: "",
    general: "",
    tenantUUID: "",
  });

  const tenant = useContext(TenantContext);

  const subdomain =
    process.env.NODE_ENV === "development"
      ? "foodclub"
      : window.location.host.split(".")[0];

  const validateTenantSubDomain = useCallback(() => {
    setErrorMessages((prevState) => ({ ...prevState, tenantUUID: "" }));

    fetch(`${config.API_URL}/tenant/validate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ subDomain: subdomain }), // make sure `subdomain` is defined
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.isValid) {
          setIsIdValid(true);
          setTenantName(data.tenantName);
          setTenantId(data.tenantId);
        } else {
          setIsIdValid(false);
          setErrorMessages((prevState) => ({
            ...prevState,
            tenantUUID: t("login.tenantIdRequired"),
          }));
        }
      })
      .catch((error) => {
        console.error("Tenant UUID validation error:", error);
        setErrorMessages((prevState) => ({
          ...prevState,
          tenantUUID: t("tenantUUIDValidationError"),
        }));
      });
  }, [subdomain, t]); // Ensure subdomain is in the dependency array

  useEffect(() => {
    if (tenant) {
      validateTenantSubDomain();
    }
  }, [validateTenantSubDomain, tenant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessages({
      username: "",
      password: "",
      confirmPassword: "",
      general: "",
      tasks: "",
    });

    if (formData.password.trim() !== formData.confirmPassword.trim()) {
      setErrorMessages((prevState) => ({
        ...prevState,
        confirmPassword: t("passwordMismatch"),
      }));
      return;
    }

    if (selectedTasks.length === 0) {
      setErrorMessages((prevState) => ({
        ...prevState,
        tasks: t("atLeastOneTaskRequired"),
      }));
      return;
    }

    const dataToSubmit = {
      ...formData,
      tasks: selectedTasks,
      tenantId,
    };

    fetch(`${config.API_URL}/user/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("userName", data.userName);
          localStorage.setItem("userRole", data.role);
          localStorage.setItem("tenantUUID", tenant);

          onLogin(); // Set isLoggedIn to true

          navigate("/logininstructions");
        } else {
          if (data.message === "Username already exists") {
            setErrorMessages((prevState) => ({
              ...prevState,
              username: t("usernameTaken"),
            }));
          } else if (data.message === "Employee number already exists") {
            setErrorMessages((prevState) => ({
              ...prevState,
              employee_nr: t("employeeNrTaken"),
            }));
          } else {
            setErrorMessages((prevState) => ({
              ...prevState,
              general: t("registrationFailed"),
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Registration error:", error);
        setErrorMessages((prevState) => ({
          ...prevState,
          general: t("errorOccurred"),
        }));
      });
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("register")}
      </h2>

      {errorMessages.general && (
        <p className="text-red-500 mb-4 text-center">{errorMessages.general}</p>
      )}

      {isIdValid && tenantName && (
        <h3 className="text-lg font-semibold mb-4 text-center text-charcoal">
          {t("tenant_name")}: {tenantName}
        </h3>
      )}

      <form onSubmit={handleSubmit} className="space-y-4 mt-4">
        <div>
          <label
            htmlFor="username"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("username")}:
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.username && (
            <p className="text-red-500">{errorMessages.username}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="employee_nr"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("employeeNumber")}:
          </label>
          <input
            type="text"
            id="employee_nr"
            name="employee_nr"
            value={formData.employee_nr}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.employee_nr && (
            <p className="text-red-500">{errorMessages.employee_nr}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("password")}:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.password && (
            <p className="text-red-500">{errorMessages.password}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("confirmPassword")}:
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.confirmPassword && (
            <p className="text-red-500">{errorMessages.confirmPassword}</p>
          )}
        </div>

        <TaskSelection
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
        />
        {errorMessages.tasks && (
          <p className="text-red-500">{errorMessages.tasks}</p>
        )}

        <button
          type="submit"
          className="w-full bg-persianGreen text-white font-semibold py-2 rounded-md hover:bg-green-700 transition duration-200"
        >
          {t("register")}
        </button>
      </form>
    </div>
  );
};

export default UserRegister;
